import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './Home.scss';
import SmallBox from 'components/SmallBox';
import { Link } from 'react-router-dom';
import { Switch, alpha, lighten, styled } from '@mui/material';
import { useStoresContext } from 'stores/StoresProvider';
import { AttackedDevice, useAlertsStoreContext } from 'stores/AlertsProvider';
import PROPERTIES from 'properties';
import { useDevicesStoreContext } from 'stores/DevicesProvider';
import ButtonCard from 'components/ButtonCard';
import { FaHeartPulse } from "react-icons/fa6"
import { BsFillShieldLockFill } from "react-icons/bs";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import StyledSelect from 'components/StyledSelect';
import { useAccountsStoreContext } from 'stores/AccountsProvider';

const { HOME: DASHBOARD } = PROPERTIES

export const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
      },
}))

const rowStyle = { padding: '0 1rem 1rem 1rem' }

export default function Home() {
    const [blockedIDSCount, setBlockedIDSCount] = useState<number>(0)
    const [nonBlockedIDSCount, setNonBlockedIDSCount] = useState<number>(0)
    const [blockedRansomwareCount, setBlockedRansomwareCount] = useState<number>(0)
    const [nonBlockedRansomwareCount, setNonBlockedRansomwareCount] = useState<number>(0)
    const stores = useStoresContext()
    const alertsStore = useAlertsStoreContext()
    const devicesStore = useDevicesStoreContext()
    const {alertsStore: AlertsStore} = stores

    const accountsStore = useAccountsStoreContext()
    
    const { serversCount, containersCount, desktopsCount, liveEndpointsCount } = devicesStore
    const { selectedAccountIndex, accounts } = accountsStore

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    useEffect(() => {
        const { authStore } = stores
    
        const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        devicesStore.loadServerData(1, "", selectedAccountId)
        devicesStore.loadDesktopData(1, "", selectedAccountId)
        devicesStore.loadContainerData(1, "", selectedAccountId)
        devicesStore.loadLiveEndpointsCount(selectedAccountId)

        const accountId = selectedAccountId !== '' ? selectedAccountId : authStore.user?.account_id
        const role = authStore.user?.role ? authStore.user.role : 'supervisor'
        if (accountId) {
            alertsStore.loadAlertsPerDay(accountId, role)
            alertsStore.loadTopIDSAttacked(accountId)
            alertsStore.loadTopRansomwareAttacked(accountId)
        }

        // Get alerts count for blocked / detected cards
        alertsStore.getAlertsCount('ids', true, selectedAccountId).then(setBlockedIDSCount)
        alertsStore.getAlertsCount('ids', false, selectedAccountId).then(setNonBlockedIDSCount)
        alertsStore.getAlertsCount('ransomware', true, selectedAccountId).then(setBlockedRansomwareCount)
        alertsStore.getAlertsCount('ransomware', false, selectedAccountId).then(setNonBlockedRansomwareCount)
    }, [selectedAccountIndex])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        TimeScale,
        TimeSeriesScale,
        LineElement,
        PointElement,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        BarElement
    )

    function onClickRansomwareEvents() {
        alertsStore.setLoadOptions({
            mitigation: 'all',
            categoriesFilter: 'detection',
            severitiesFilter: 'ransomware'
        })
    }

    function onClickIDSEvents() {
        alertsStore.setLoadOptions({
            mitigation: 'all',
            categoriesFilter: 'detection',
            severitiesFilter: 'ids'
        })
    }

    function onClickEventsThatNeedAtention() {
        alertsStore.setLoadOptions({ mitigation: 'unblocked', severitiesFilter: 'ransomware'})
    }

    const totalDevicesCount = serversCount + containersCount + desktopsCount
   
    function onClickIDSEndpoint() {
        AlertsStore.setLoadOptions({severitiesFilter: 'ids'})
    }

    function onClickRansomwareEndpoint() {
        AlertsStore.setLoadOptions({severitiesFilter: 'ransomware'})
    }

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                align: 'start' as "start",
                position: 'bottom' as "bottom"
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "#6e9693",
                }
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: 1,
                    color: "#6e9693"
                },
                beginatZero: true
            }
        }
    }

    const alertsPerDayData = {
        labels: alertsStore.ransomwareAlertsPerDay.labels,
        datasets: [
            {
                label: DASHBOARD.RANSOMWARE_LEGEND,
                data: alertsStore.ransomwareAlertsPerDay.values,
                borderColor: '#95a0b6',
                backgroundColor: 'rgba(255, 0, 0, .3)',
                borderWidth: 1,
                weight: 10,
                cutout: 80,
                offset: 30
            },
            {
                label: DASHBOARD.IDS_LEGEND,
                data: alertsStore.idsAlertsPerDay.values,
                borderColor: '#95a0b6',
                backgroundColor: 'rgba(255, 145, 0, 0.893)',
                borderWidth: 1,
                weight: 10,
                cutout: 80,
                offset: 30
            }
        ],
    }

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })
    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]

    return (
        <div className="container-fluid">
            <TitleContainer style={{marginRight: '23px'}}>
                <h1>{DASHBOARD.TITLE}</h1>
                <StyledSelect
                    label="Account"
                    handleOnChange={accountsStore.setSelectedAccountIndex}
                    options={selectOptions}
                    value={selectedAccountIndex}/>
            </TitleContainer>
            <div className="row" style={rowStyle}>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={`${liveEndpointsCount} / ${totalDevicesCount}`}
                        title={<span>Live / Installed <br/> Endpoints</span>}
                        muiIcon={<HeartIcon size={'2rem'} />}
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <ButtonCard
                        count={`${blockedRansomwareCount} / ${blockedRansomwareCount + nonBlockedRansomwareCount}`}
                        title={<span>Blocked / Detected <br/> Ransomware Events</span>}
                        icon={<BsFillShieldLockFill size={'2rem'} color="#6858ac" />}
                        onClick={onClickRansomwareEvents}
                        to='/alerts'
                        />
                </div>
                <div className="col-lg-3 col-6">
                    <ButtonCard
                        count={`${blockedIDSCount} / ${blockedIDSCount + nonBlockedIDSCount}`}
                        title={<span>Blocked / Detected <br/> Intrusion Events</span>}
                        icon={<BsFillShieldLockFill size={'2rem'} color="#6858ac" />}
                        onClick={onClickIDSEvents}
                        to='/alerts'
                        />
                </div>
                <div className="col-lg-3 col-6">
                    <ButtonCard
                        count={nonBlockedRansomwareCount}
                        title={DASHBOARD.NEED_ATENTION}
                        icon={<GppMaybeIcon fontSize='large' color='warning'/>}
                        onClick={onClickEventsThatNeedAtention}
                        to='/alerts'
                        />
                </div>
            </div>
            <div className="row" style={{padding: '1rem'}}>
                <div className="col-lg-12 col-12">
                    <h4 style={{color: 'white', marginBottom: 20, fontWeight: 'bolder', marginLeft: 30}}>{DASHBOARD.ALERTS_PER_DAY}</h4>
                    <div className="card" style={{backgroundColor: '#222227'}}>
                        <div className="card-body">
                            <Bar
                                height={"300 px"}
                                options={barChartOptions}
                                data={alertsPerDayData}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <div className='container-tops'>
                <div className='container-tops-attacked-endpoint'>
                    <p>Top Ransomware Alerted Endpoint</p>{/*the icon has to come in the endpoint*/}
                    <div className='container-tops-attacked-endpoint-content'>
                        {alertsStore.topRansomwareAttacked ? alertsStore.topRansomwareAttacked.map((dev: AttackedDevice, index: number) => (
                            <section key={index}>
                                <span className='span-1'><StyledLink onClick={onClickRansomwareEndpoint} to={`/endpoint/${dev.id}/monitoring`}><b>{dev.hostname}</b></StyledLink></span>
                                <span className='span-2'>{dev.totalAttacks}</span>
                            </section>
                        )) : null}
                    </div>
                </div>
                <div className='container-tops-attacked-endpoint'>
                    <p>Top Intrusion Alerted Endpoint</p>{/*the icon has to come in the endpoint*/}
                    <div className='container-tops-attacked-endpoint-content'>
                        {alertsStore.topAttacked ? alertsStore.topAttacked.map((dev: AttackedDevice, index: number) => (
                            <section key={index}>
                                <span className='span-1'><StyledLink onClick={onClickIDSEndpoint} to={`/endpoint/${dev.id}/monitoring`}><b>{dev.hostname}</b></StyledLink></span>
                                <span className='span-2'>{dev.totalAttacks}</span>
                            </section>
                        )) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const StyledLink = styled(Link)<{ disabled?: boolean}>(({ theme, disabled }) => ({
    color: disabled ? 'gray' : theme.palette.primary.dark,
    '&:hover': {
        color: disabled ? 'gray' : `${lighten(theme.palette.primary.dark, 0.25)}`
    }
}))

const HeartIcon = styled(FaHeartPulse)(({theme}) => ({
    color: theme.palette.primary.dark
}))

export const TitleContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    margin-bottom: 20px;
`
