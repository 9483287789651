import { createContext, useContext, useState } from "react"
import { makeAuthenticatedRequest } from "utils/API"
import { Device, Metadata } from "./DevicesStore"
import { Order } from "./AlertsStore"

const LIVE_ENDPOINT_LAST_TRAILING_DAYS = 14

export type DeviceKey = 'ip' | 'hostname' | 'agent_version'

interface DevicesStore {
    loading: boolean
    servers: Device[]
    desktops: Device[]
    containers: Device[]
    serversCount: number
    desktopsCount: number
    containersCount: number
    liveEndpointsCount: number
    cursorCurrent: string
    cursorBefore: string
    cursorAfter: string
    cursorLast: string
    order: Order
    orderBy: DeviceKey
    loadServerData: (count: number, cursor: string, accountId?: string) => void
    loadDesktopData: (count: number, cursor: string, accountId?: string) => void
    loadContainerData: (count: number, cursor: string, accountId?: string) => void
    loadLiveEndpointsCount: (accountId?: string) => void
    setOrder: (order: Order) => void
    setOrderBy: (orderBy: DeviceKey) => void
    setLoadOptions: (options: LoadOptions) => void
    deleteDevice: (device: Device) => Promise<void>
}

type DevicesResponse = {
    items: Device[],
    total: number,
    metadata: Metadata
}

const DevicesContext = createContext<DevicesStore | undefined>(undefined)

export function useDevicesStoreContext() {
    const stores = useContext(DevicesContext)
    if (stores === undefined)
        throw new Error('useDevicesStoreContext must be use wrapped around a DevicesProvider')
    return stores
}

export type LoadOptions = {
    orderBy?: DeviceKey
    order?: Order
    selectedAccountId?: string
}

type DeviceType = 'server' | 'desktop' | 'container'

export default function DevicesProvider({children}: {children: any}) {
    const [loading, setLoading] = useState<boolean>(false)
    const [cursorCurrent, setCursorCurrent] = useState<string>('')
    const [cursorBefore, setCursorBefore] = useState<string>('')
    const [cursorAfter, setCursorAfter] = useState<string>('')
    const [cursorLast, setCursorLast] = useState<string>('')
    const [servers, setServers] = useState<Device[]>([])
    const [desktops, setDesktops] = useState<Device[]>([])
    const [containers, setContainers] = useState<Device[]>([])
    const [serversCount, setServersCount] = useState<number>(0)
    const [desktopsCount, setDesktopsCount] = useState<number>(0)
    const [containersCount, setContainersCount] = useState<number>(0)
    const [liveEndpointsCount, setLiveEndpointsCount] = useState<number>(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<DeviceKey>('hostname')

    async function loadDevicesData(count: number, cursor: string, deviceType: DeviceType, setDevices: (devices: Device[]) => void, setCount: (count: number) => void, accountId?: string) {
        setLoading(true)
        setCursorCurrent(cursor)

        // Build url
        const sort = `${order === 'desc' ? '-' : ''}${orderBy}`
        let url = '/api/v2/devices'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/devices`
        url = url.concat(`?sort=${sort}&count=${count}&cursor=${cursor}&device_type=${deviceType}&include_subaccounts=true`)

        return makeAuthenticatedRequest({ url })
            .then((res: DevicesResponse) => {
                setDevices(res.items)
                setCount(res.total)
                setCursors(res.metadata)
                setLoading(false)
            })
            .catch(onApiFailure)
    }

    async function loadServerData(count: number, cursor: string, accountId?: string) {
        return loadDevicesData(count, cursor, 'server', setServers, setServersCount, accountId)
    }

    async function loadDesktopData(count: number, cursor: string, accountId?: string) {
        return loadDevicesData(count, cursor, 'desktop', setDesktops, setDesktopsCount, accountId)
    }

    async function loadContainerData(count: number, cursor: string, accountId?: string) {
        return loadDevicesData(count, cursor, 'container', setContainers, setContainersCount, accountId)
    }

    async function loadLiveEndpointsCount(accountId?: string) {
        // Build url
        let url = '/api/v2/devices'
        if (accountId && accountId !== '')
            url = `/api/v2/accounts/${accountId}/devices`
        const now = new Date()
        now.setDate(now.getDate() - LIVE_ENDPOINT_LAST_TRAILING_DAYS)
        url = url.concat(`?last_heartbeat_from=${now.toISOString()}&include_subaccounts=true&count=1`)

        return makeAuthenticatedRequest({ url })
            .then(onLoadLiveEnpointsSuccess)
            .catch(onApiFailure)
    }

    function onLoadLiveEnpointsSuccess(response: DevicesResponse) {
        setLiveEndpointsCount(response.total)
    }

    function setCursors(metadata: Metadata) {
        if (metadata != null) {
            if (metadata.cursor_after != null)
                setCursorAfter(metadata.cursor_after)
            else
                setCursorAfter('')
            if (metadata.cursor_before != null)
                setCursorBefore(metadata.cursor_before)
            else
                setCursorBefore('')
            if (metadata.cursor_last != null)
                setCursorLast(metadata.cursor_last)
            else
                setCursorLast('')
        }
    }

    function setLoadOptions(options: LoadOptions) {
        if (options?.orderBy)
            setOrderBy(options.orderBy)
        if (options?.order)
            setOrder(options.order)
    }

    async function deleteDevice(device: Device) {
        setLoading(true)
        let url = `/api/v2/accounts/${device.account_id}/devices/${device.id}`
        if (!device.account_id)
            url = `/api/v1/users/${device.user_id}/devices/${device.id}`

        return makeAuthenticatedRequest({
            url,
            options: { method: 'DELETE' }
        })
        .catch(onApiFailure)
    }

    function onApiFailure(e: Error) {
        setLoading(false)
        throw e
    }

    return (
        <DevicesContext.Provider value={{
            loading,
            servers,
            desktops,
            containers,
            serversCount,
            desktopsCount,
            containersCount,
            liveEndpointsCount,
            cursorCurrent,
            cursorBefore,
            cursorAfter,
            cursorLast,
            order,
            orderBy,
            loadServerData,
            loadDesktopData,
            loadContainerData,
            loadLiveEndpointsCount,
            setOrder,
            setOrderBy,
            setLoadOptions,
            deleteDevice
        }}>
            { children }
        </DevicesContext.Provider>
    )
}
