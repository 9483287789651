const PROPERTIES = {
    ACCOUNTS: {
        NAME: 'Name',
        SUBSCRIPTION_MONTHS: 'Subscription Months',
        LAST_SUBSCRIPTION: 'Subscription Start Date',
        USERS_LIMIT: 'Users Limit',
        DEVICES_LIMIT: 'Devices Limit',
        TOOLTIP: {
            SUBSCRIPTION_MONTHS: 'The number of months your subscription will be active since the last subscription date',
            LAST_SUBSCRIPTION: 'The last date that your subscription was renewed',
            USERS_LIMIT: 'The maximum number of users that can be added to your account',
            DEVICES_LIMIT: 'The maximum number of devices that can be registered with your account',
            ADVANCED_POWERSHELL_PROTECTION: 'AMSI file scanning for PowerShell',
            ADVANCED_POWERSHELL_NOTIFICATIONS: 'Toast message triggers when scanner detects malicious file',
        },
        PROTECTION_OPTIONS: {
            OFF: 'Off',
            ONLY_DETECTION: 'Detection',
            DETECTION_AND_BLOCKING: 'Detection + Blocking',
            EXPLANATION: {
                ONLY_DETECTION: 'No automatic mitigation',
                DETECTION_AND_BLOCKING: 'Automatic mitigation'
            }
        }
    },
    EVENTS: {
        TITLE: 'Events',
        SEVERITY: 'Severity',
        IP: 'IP Address',
        HOSTNAME: 'Hostname',
        TIME: 'Time',
        SUMMARY: 'Summary',
        DESCRIPTION: 'Description',
        MITIGATED: 'Blocked',
        DOWNLOAD_CSV: 'Download Events CSV'
    },
    HOME: {
        TITLE: 'Home',
        LIVE_ENDPOINTS: `Live / Installed Endpoints`,
        BLOCKED_IDS: 'Blocked / Detected IDS Events',
        BLOCKED_RANSOMWARE: 'Blocked / Detected Ransomware Events',
        NEED_ATENTION: 'Events Needing Attention',
        ALERTS_PER_DAY: 'Events per Day',
        RANSOMWARE_LEGEND: 'Ransomware Events',
        IDS_LEGEND: 'Intrusion Events'
    },
    DOWNLOAD: {
        TITLE: 'Download',
        WINDOWS_DESKTOPS: {
            TITLE: 'Windows Desktops'
        },
        DOWNLOAD_BUTTON: 'Download'
    },
    INCLUSION_LISTS: {
        TITLE: 'Memory Protection Inclusion Lists',
        SUBHEADER: 'Set processes to be covered by memory protection',
        SET_TO_ENDPOINT: 'Set to all endpoints',
        SNACKBAR_SET: {
            SUCCESS: 'Memory protection inclusion list set successfully',
            ERROR: 'Error while setting protected list'
        },
        SNACKBAR_SHARE: {
            SUCCESS: 'Memory protection inclusion list shared successfully',
            ERROR: 'Error while sharing protected list'
        },
        DIALOG: {
            TITLE: 'Create List',
            LIST_NAME: 'List Name',
            LIST_OF_PROGRAMS: 'Comma Separated List of Programs',
            SAVE: 'Save'
        }
    },
    SETTINGS: {
        TITLE: 'Settings',
        ENABLE_ALL: 'Enable all',
        DISABLE_ALL: 'Disable all',
        EXCLUSION_LIST: '',
        MEM_PROTECT_LIST: 'Inclusion Lists',
        ZERO_TRUST: {
            TITLE: 'Zero Trust',
            RINGFENCE: {
                TITLE: 'Application Ringfencing',
                TOOLTIP: 'Prevents applications from launching in folders commonly associated with malware'
            }
        },
        BREACH_PREVENTION: {
            TITLE: 'Breach Prevention',
            NOTIFICATIONS: {
                TITLE: 'Breach Notifications',
                MEMORY_NOTIFICATIONS: {
                    TITLE: 'Memory Notifications',
                    TOOLTIP: 'Displays toast messages on user’s machine when a memory protection event is triggered'
                } ,
                SERVICE_NOTIFICATIONS: {
                    TITLE: 'Service Notifications',
                    TOOLTIP: 'Displays toast messages on user’s machine when a system service event is triggered'
                } ,
                SIMPLE_POWERSHELL_NOTIFICATIONS: {
                    TITLE: 'PowerShell Notifications',
                    TOOLTIP: 'Displays toast messages on user’s machine when a PowerShell protection event is triggered'
                }
            },
            PREVENTION: {
                TITLE: 'Prevention',
                MEMORY_PROTECTION: {
                    TITLE: 'Memory Protection',
                    TOOLTIP: 'Provides in-memory randomization and honeypot traps to stop in-process memory corruption behavior'
                },
                SERVICE_PROTECTION: {
                    TITLE: 'Service Protection',
                    TOOLTIP: 'Provides system level monitoring and analysis for macro level attack behavior'
                } ,
                SIMPLE_POWERSHELL_PROTECTION: {
                    TITLE: 'PowerShell Protection',
                    TOOLTIP: 'Analyzes PowerShell commands for suspicious structure and malicious intent'
                }
            }
        },
        RANSOMWARE_PROTECTION: {
            TITLE: 'Ransomware Protection',
            DETECTION: {
                TITLE: 'Detection',
                DECOY: {
                    TITLE: 'Decoy Files',
                    TOOLTIP: 'Detects unauthorized modification of honeypot files'
                },
                FILE_ENTROPY: {
                    TITLE: 'File Entropy Analysis',
                    TOOLTIP: 'Analyzes entropy of file extensions for encryption activity'
                },
            },
            RECOVERY: {
                TITLE: 'Recovery',
                HIDDEN_ARCHIVAL: {
                    TITLE: 'Intelligent Hidden Archival',
                    TOOLTIP: 'Files are backed up in a hidden and protected archive directory only upon active read operations'
                },
                LOCK_HIDDEN_ARCHIVAL: {
                    TITLE: 'Lock Intelligent Hidden Archival',
                    TOOLTIP: 'Deactivates daily refreshing to preserve backed up files upon the detection of a ransomware attack'
                },
                ENCRYPTION_KEY_CAPTURE: {
                    TITLE: 'Encryption Key Capture',
                    TOOLTIP: 'Encryption operation metadata such as the encryption key is stored to facilitate recovery. Blocking will result in storing of metadata of potential operation plus not allow the encryption operation to be performed'
                }
            }
        },
    },
    MONITORING: {
        RANSOMWARE_BACKUP: 'Ransomware protection restore from backup'
    }
}

export default PROPERTIES
