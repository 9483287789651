import React, { useEffect, useState } from 'react';
import './Endpoints.scss';
import { DeviceKey, useDevicesStoreContext } from 'stores/DevicesProvider';
import DesktopsTable from './DesktopsTable';
import { Order } from 'stores/AlertsStore';
import { Container } from '@mui/material';
import StyledSelect from 'components/StyledSelect';
import { useAccountsStoreContext } from 'stores/AccountsProvider';
import { ScreenContainer } from '../settings/Settings';

const DEFAULT_ROWS_PER_PAGE = 10
var selectedAccountId = ""

export default function Endpoints() {
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)

    const devicesStore = useDevicesStoreContext()
    const accountsStore = useAccountsStoreContext()

    const { loading, serversCount, desktopsCount, order, orderBy } = devicesStore
    const { selectedAccountIndex, accounts } = accountsStore

    const totalCount = serversCount  + desktopsCount

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    useEffect(() => {
        const accountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        selectedAccountId = accountId
        devicesStore.loadDesktopData(rowsPerPage, "", accountId)
    }, [rowsPerPage, order, orderBy, selectedAccountIndex])

    function handlePageChange(newPage: number, oldPage: number) {
        if (selectedAccountId === "") {
            if ((newPage - oldPage) === 1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorAfter)
            else if ((newPage - oldPage) > 1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorLast)
            else if (newPage === 0)
                devicesStore.loadDesktopData(rowsPerPage, "")
            else if ((newPage - oldPage) === -1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorBefore)
        }
        else {
            if ((newPage - oldPage) === 1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorAfter,selectedAccountId)
            else if ((newPage - oldPage) > 1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorLast,selectedAccountId)
            else if (newPage === 0)
                devicesStore.loadDesktopData(rowsPerPage, "",selectedAccountId)
            else if ((newPage - oldPage) === -1)
                devicesStore.loadDesktopData(rowsPerPage, devicesStore.cursorBefore,selectedAccountId)
        }
    }

    function handleSort(order: Order, orderBy: string) {
        if (orderBy === devicesStore.orderBy)
            devicesStore.setOrder(order)
        else {
            devicesStore.setOrder('asc')
            devicesStore.setOrderBy(orderBy as DeviceKey)
        }
    }

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })
    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]
    const accountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}} className="Endpoints">
            <div className="title-container">
                <div className="title-info-container">
                    <h1 className="title-endpoints">Endpoints</h1>
                    <span className="total-endpoints">Total Endpoints Connected: {totalCount}</span>
                </div>
                <StyledSelect
                    label="Account"
                    handleOnChange={accountsStore.setSelectedAccountIndex}
                    options={selectOptions}
                    value={selectedAccountIndex}/>
            </div>

            <Container disableGutters style={{maxWidth: '-webkit-fill-available'}}>
                <h1 className='title-servers'>Desktops</h1>
                <DesktopsTable
                    devices={devicesStore.desktops}
                    loading={loading}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    onSort={handleSort}
                    rowsPerPage={rowsPerPage}
                    order={order}
                    orderBy={orderBy}
                    accountId={accountId}/>
            </Container>
            {/* <CardEndpoints>
                <h1 className='title-servers'>Servers</h1>
                <EndpointsTable loadingState={loading} sortBy={sortBy}/>
            </CardEndpoints> */}
        </ScreenContainer>
    )
}