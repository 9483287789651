import { ToggleButton, ToggleButtonGroup, alpha, styled } from "@mui/material";

interface StyledToggleButtonGroupProps {
    value: string
    handleOnChange: (value: string) => void
    options: { value: string, label: string }[]
}

export default function StyledToggleButtonGroup({ value, handleOnChange, options, ...props }: StyledToggleButtonGroupProps) {
    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(event: any) => handleOnChange(event.target.value)}
            sx={{marginBottom: '10px'}}
            {...props}
        >
            {options.map((option, index) => 
                <StyledToggleButton key={index} value={option.value}>{option.label}</StyledToggleButton>
            )}
        </ToggleButtonGroup>
    )
}

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
    }
}))